var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-btn',{staticClass:"mr-1",attrs:{"dense":"","icon":""}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"large":""},on:{"click":_vm.goContact}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")])],1),_c('v-col',{staticClass:"mt-2 p-0",attrs:{"justify":"center"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.2em"}},[_vm._v(_vm._s(_vm.form.name))])])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mb-0 pb-0 mt-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"#1F979E","dense":""}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocument)+" ")]),_vm._v(" "+_vm._s(_vm.$t('import'))+" ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"#1F979E","dense":""}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocument)+" ")]),_vm._v(" "+_vm._s(_vm.$t('Export'))+" ")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":function($event){return _vm.$refs.dialogAddResponse.open(null, _vm.tags)}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('Add Response'))+" ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"item-key":"id","headers":_vm.customHeaders,"items":_vm.customDataResponse,"loading":_vm.tableLoading,"show-select":"","footer-props":{
      'items-per-page-text': _vm.$t('rowsPerPageLabel'),
      'items-per-page-options': [5, 10, 25, 50, 75, 100],
    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ml-n4",attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.editResponse(item.response_id)}}},[_c('v-icon',{attrs:{"color":"#1F979E"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1),_c('v-btn',{attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.deleteResponse(item.id, item.response_id)}}},[_c('v-icon',{attrs:{"color":"#1F979E"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1)]}}],null,true)})]}},{key:"item.user",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"white-space":"initial","max-width":"20vh"}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.getProfPic(item.user.profile_picture, item),"lazy-src":_vm.defaultProfPic,"height":"40","width":"40"}})],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary phone-number",staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.user.name))]),_c('span',{staticClass:"text-xs",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(item.user.phoneNumber)+" ")])])],1)]}}],null,true)}),_c('confirm',{ref:"confirm"}),_c('dialogAddResponse',{ref:"dialogAddResponse",attrs:{"form-data":_vm.form,"response-data":_vm.customDataResponse},on:{"fetch-survey":_vm.fetchSurvey,"fetch-response":_vm.fetchResponse}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }